import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { useTheme } from 'context/theme';
import styles from 'styles/TopNav.module.scss';

export type NavbarDropdownItemProps = {
  href: string;
} & PropsWithChildren;

export const NavbarDropdownItem: React.FC<NavbarDropdownItemProps> = ({ children, href }) => {
  const { isDark } = useTheme();

  return (
    <li>
      <Link
        style={{ color: isDark ? 'rgba(255, 252, 249, 0.9)' : undefined }}
        className={classNames(styles.navbarDropdownItem, 'd-block mb-3 cursor-pointer')}
        href={href}
      >
        {children}
      </Link>
    </li>
  );
};
