import { Nav } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import styles from 'styles/TopNav.module.scss';
import { StyledComponent } from 'types';

type NavbarDropdownItemMobileProps = {
  onDropdownClick?: () => void;
  title: React.ReactNode | string;
  isOpen?: boolean;
  items: { title: React.ReactNode | string; href: string; featureFlag?: string }[];
} & StyledComponent;

export const NavbarDropdownItemMobile: React.FC<NavbarDropdownItemMobileProps> = ({
  onDropdownClick,
  isOpen,
  title,
  items,
  className,
  style
}) => {
  const { isDark } = useTheme();

  const careTitle = (
    <div
      className={classNames(styles['dropdown-title'], 'd-flex justify-content-between', {
        [styles.darkNav]: isDark
      })}
    >
      {title}
      <IoIosArrowDown
        size={18}
        color={isDark ? COLORS.primaryLight : COLORS.primary}
        className={classNames(styles['dropdown-carret'], { [styles.rotate]: isOpen })}
      />
    </div>
  );

  return (
    <NavDropdown
      renderMenuOnMount
      title={careTitle}
      className={classNames(styles['shop-mobile-dropdown'], className)}
      onClick={onDropdownClick}
      show={isOpen}
      style={style}
    >
      {items.map(({ title: label, href }) => (
        <NavbarDropdownItemLink key={href} label={label} href={href} />
      ))}
    </NavDropdown>
  );
};

const NavbarDropdownItemLink = ({ label, href }: { label: React.ReactNode | string; href: string }) => {
  const { isDark } = useTheme();

  return (
    // legacyBehavior has to be added because Nav.Link adds an extra a tag
    <div className={styles['border-except-last']}>
      <Link legacyBehavior passHref href={href}>
        <Nav.Link
          className={classNames(styles.brightOnHover, {
            [styles.darkNav]: isDark
          })}
        >
          {label}
        </Nav.Link>
      </Link>
    </div>
  );
};
