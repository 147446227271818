import { CSSProperties } from 'react';

export type IdAndName = {
  id: number;
  name: string;
  slug?: string;
};

export type MomentType = IdAndName;
export type Material = IdAndName;
export type ClaspType = IdAndName;
export type Color = IdAndName;
export type Numerals = IdAndName;
export type Gender = IdAndName;
export type Crystal = IdAndName;
export type ClaspMaterial = IdAndName;
export type Image = {
  id: number;
  rawUrl: string;
  optimizedUrl: string;
  width: number;
  height: number;
  url: string;
};

export enum ListingImageType {
  ACCESSORY = 'ACCESSORY',
  BRACELET_TOP = 'BRACELET_TOP',
  BRACELET_BOTTOM = 'BRACELET_BOTTOM',
  OPPOSITE_CROWN_SIDE = 'OPPOSITE_CROWN_SIDE',
  BACK = 'BACK',
  FRONT = 'FRONT',
  CROWN_SIDE = 'CROWN_SIDE',
  VERIFICATION = 'VERIFICATION',
  CLASP = 'CLASP'
}

export type ImageWithType = {
  type: ListingImageType;
  image: Image;
};

export type QueryParams = Record<string, string | number | boolean | undefined | (string | number)[]>;

export type QueryParam = string | string[] | undefined;

export type Paginated<T> = {
  totalHitCount: number;
  page: number;
  hits: T[];
  totalListingCount: number;
};

export type StyledComponent = {
  className?: string;
  style?: CSSProperties;
};

export type RGBColorObject = {
  r: number;
  g: number;
  b: number;
};
