import { FC } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { TopnavTitleKey, useGetTopNavbar } from 'context/topNavbarProvider';
import { BuyerProfile } from 'types';
import { formatPrice } from 'utils/text-helpers';

import { commonTopnavStripClasses } from '../TopNav/constants';

export const CustomRibbonTestIds = {
  container: 'custom-ribbon-container'
};

export type CustomRibbonProps = {
  buyerProfile: BuyerProfile;
};

export const CustomRibbon: FC<CustomRibbonProps> = ({ buyerProfile }) => {
  const { topNavbarTitleKey } = useGetTopNavbar();
  const router = useRouter();

  const navigateToReferrals = () => router.push('/refer');

  const titlesMap = {
    [TopnavTitleKey.Default]: (
      <div onClick={navigateToReferrals} role='button'>
        {`Refer Friends, Get ${formatPrice(buyerProfile.referralRewardCents)}`}
        <span className='text-light text-uppercase ms-4 opacity-60 d-none d-sm-inline'>LEARN MORE</span>
      </div>
    ),
    [TopnavTitleKey.OwnListing]: <div>{`This is your listing. You're able to edit it directly`}</div>
  };

  return (
    <div
      data-testid={CustomRibbonTestIds.container}
      className={classNames(
        commonTopnavStripClasses,
        topNavbarTitleKey === TopnavTitleKey.Default && 'top-nav-hover cursor-pointer'
      )}
    >
      {titlesMap[topNavbarTitleKey]}
    </div>
  );
};
