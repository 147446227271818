import { CSSProperties, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FaBars } from '@react-icons/all-files/fa6/FaBars';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { MobileSearch } from 'components/search/MobileSearch';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import { intercomHideLauncher, intercomShowLauncher } from 'lib/intercom';
import styles from 'styles/TopNav.module.scss';

import { ShopItemsMobile } from './ShopItemsMobile';

import { TopNavAuthSection } from '../../TopNavAuthSection';
import { TopNavCheckout } from '../../TopNavCheckout';
import { TopNavStrip } from '../../TopNavStrip';
import { BezelLogo } from '../BezelLogo';
import { containerStyle } from '../constants';
import { useScroll } from '../useScroll';

const MOBILE_TOP_NAV_HEIGHT = 125;

export const TopNavMobileTestIds = {
  container: 'top-nav-mobile-container',
  navBar: 'top-nav-mobile-nav'
};

const expandedStyle: CSSProperties = { height: '100vh', background: 'transparent' };
const logoHeight = 30;

export const TopNavMobile = () => {
  const router = useRouter();
  const scrolling = useScroll();
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [minHeight, setMinHeight] = useState<string | number>(0);
  const { isDefault } = useTheme();

  const isVisible = !router.pathname.includes('/checkout');

  const onLogoClick = () => {
    // Ensure that mobile navbar is collapsed whenever user clicks on logo to return to homepage
    if (navbarExpanded) {
      setNavbarExpanded(false);
    }
  };

  useEffect(() => {
    // TODO replace this logic with CSS
    const callback = () => {
      setMinHeight(window.innerHeight - MOBILE_TOP_NAV_HEIGHT);
    };

    callback();
    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, []);

  useEffect(() => {
    if (navbarExpanded) {
      intercomHideLauncher();
    } else {
      intercomShowLauncher();
    }
  }, [navbarExpanded]);

  useEffect(() => {
    document.body.style.overflow = navbarExpanded ? 'hidden' : 'auto'; // to prevent body scrolling when mobile dropdown is open
  }, [navbarExpanded]);

  return (
    <>
      {isVisible && <TopNavStrip />}
      <div
        data-testid={TopNavMobileTestIds.container}
        className='position-sticky top-0 w-100'
        style={{
          ...containerStyle,
          ...(navbarExpanded ? expandedStyle : {})
        }}
      >
        {isVisible && (
          <>
            <Navbar
              collapseOnSelect
              data-testid={TopNavMobileTestIds.navBar}
              onToggle={setNavbarExpanded}
              expand='lg'
              id='bezel-top-nav'
              style={{ backgroundColor: isDefault ? COLORS.primaryLight : COLORS.primaryDark }}
              className={classNames('py-3 w-100', styles.navigation, navbarExpanded && 'shadow h-100')}
              expanded={navbarExpanded}
            >
              <div className='container-fluid p-0 position-relative' style={{ minHeight: `${logoHeight}px` }}>
                <div className='d-flex flex-nowrap align-items-center justify-content-between flex-grow-1 px-3'>
                  <BezelLogo isDefault={isDefault} onClick={onLogoClick} logoHeight={logoHeight} />
                  <MobileSearch />
                </div>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' className={classNames(styles.hamburger, 'pe-4')}>
                  <FaBars size='0.875em' color={isDefault ? undefined : COLORS.primaryLight} />
                </Navbar.Toggle>
              </div>
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className={classNames(styles['navbar-collapse'], 'riforma-regular')}
              >
                <Nav style={{ height: minHeight }} className='my-1 ms-auto d-flex flex-column justify-content-between'>
                  <ShopItemsMobile />
                  <TopNavAuthSection isTabletOrMobile />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </>
        )}
        {!isVisible && <TopNavCheckout scrolling={scrolling} logoWidth={logoHeight} />}
      </div>
    </>
  );
};
