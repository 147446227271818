import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import useSWR from 'swr';

import { useBezelUser } from 'context/bezelUser';
import { SellerProfile } from 'types/sellerProfile';

import { clientApiGet } from '../lib/api_helper';
import { apiUrls } from '../lib/api_urls';
import { SellerLevel } from '../types';

export const SellerProfileContext = createContext<{
  loading: boolean;
  sellerProfile: SellerProfile | null;
  sellerLevels?: SellerLevel[];
}>({ loading: false, sellerProfile: null, sellerLevels: undefined });

type SellerProfileContextProviderProps = PropsWithChildren;

export const SellerProfileContextProvider: React.FC<SellerProfileContextProviderProps> = ({ children }) => {
  const { user, isLoading } = useBezelUser();
  const sellerProfile = user ? user.sellerProfile : null;
  const { data: sellerLevels } = useSWR<SellerLevel[]>(
    sellerProfile ? apiUrls.sellerLevels({}, { serverApi: true, includeBaseUrl: false }) : null,
    clientApiGet
  );

  const value = useMemo(
    () => ({ loading: isLoading, sellerProfile, sellerLevels }),
    [isLoading, sellerProfile, sellerLevels]
  );

  return <SellerProfileContext.Provider value={value}>{children}</SellerProfileContext.Provider>;
};

export const useSellerProfile = () => {
  const ctx = useContext(SellerProfileContext);

  return ctx.sellerProfile;
};
