export enum IssueCategory {
  Face = 'FACE',
  Case = 'CASE',
  Bracelet = 'BRACELET'
}

export type Issue = {
  id: number;
  name: string;
  answerRequired?: boolean;
  category: IssueCategory;
  question?: string;
};
