import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

import { NavbarDropdownMobile } from '../../NavbarDropdown';

export const ShopItemsMobile: FC = () => {
  const router = useRouter();
  const { isDark } = useTheme();
  const classes = isDark ? styles.darkNav : `${styles.navItem} ${styles.brightOnHover}`;

  const isOnExplorePage = router.pathname.includes('/explore');

  return (
    <div id='nav-items' className='d-flex flex-column mt-3 mb-3 mr-md-1 overflow-auto hide_scroll_bar'>
      <div className={styles.border}>
        {/* legacyBehavior has to be added because Nav.Link adds an extra a tag */}
        <Link legacyBehavior passHref href={pageRoutes.explore()}>
          <Nav.Link
            active={isOnExplorePage}
            className={classNames(classes, {
              'opacity-100': isOnExplorePage
            })}
          >
            Shop
          </Nav.Link>
        </Link>
      </div>
      <NavbarDropdownMobile />
    </div>
  );
};
