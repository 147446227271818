import { CSSProperties } from 'react';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { useTheme } from 'context/theme';

type LoadingProps = {
  size?: 'fullScreen' | 'fullScreenWithHeader' | 'default';
};

export const LoadingTestIds = {
  container: 'loading-container'
};

export const Loading: React.FC<LoadingProps> = ({ size = 'fullScreenWithHeader' }) => {
  const { isDark } = useTheme();

  let style: CSSProperties | undefined = undefined;

  if (size === 'fullScreenWithHeader') {
    style = { height: '85vh' };
  } else if (size === 'fullScreen') {
    style = { height: '100vh' };
  }

  return (
    <div
      className={classNames('d-flex align-items-center justify-content-center', { 'bg-darkTheme': isDark })}
      style={style}
      data-testid={LoadingTestIds.container}
    >
      <div className='main-content d-flex flex-column justify-content-center align-items-center'>
        <CloudinaryImage
          priority
          alt='Loading spinner'
          data-testid='spinner'
          width={isDark ? 120 : 80}
          height={isDark ? 94.5 : 63}
          src={isDark ? 'bezel-perp-loader-dark.gif' : 'bezel-perp-loader.gif'}
        />
      </div>
    </div>
  );
};
