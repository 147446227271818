import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

export type Theme = 'default' | 'dark';

const ThemeContext = createContext<{
  isDark: boolean;
  isDefault: boolean;
}>({ isDark: false, isDefault: true });

type ThemeProviderProps = {
  theme?: Theme;
} & PropsWithChildren;

export const ThemeProvider = ({ theme, children }: ThemeProviderProps) => {
  const [themeValue, setThemeValue] = useState<Theme>('default');

  useEffect(() => {
    if (theme) {
      setThemeValue(theme);
    } else {
      setThemeValue('default');
    }
  }, [theme]);

  const contextValue = useMemo(
    () => ({
      isDark: themeValue === 'dark',
      isDefault: themeValue === 'default'
    }),
    [themeValue]
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
