import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Button } from 'components/design-system/Button';
import { useBezelUser } from 'context/bezelUser';
import { useTheme } from 'context/theme';
import { intercomShutdown } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

import { dropdownItems } from './NavbarDropdown/consts';
import { TopNavAuthDropdownItem } from './TopNavAuthDropdownItem';

type TopNavAuthSectionProps = {
  isTabletOrMobile: boolean;
};

export const TopNavAuthSection: FC<TopNavAuthSectionProps> = ({ isTabletOrMobile }) => {
  const { push } = useRouter();
  const { user } = useBezelUser();
  const { isDark } = useTheme();

  return (
    <div
      id='auth-items'
      className={classNames(
        'd-flex flex-column flex-lg-row-reverse align-items-lg-center border-lg-start px-4 px-lg-0 ps-lg-1 ps-xl-3',
        !isTabletOrMobile && 'border-start',
        { 'border-start': !isTabletOrMobile && !isDark, [styles.borderDark]: !isTabletOrMobile && isDark }
      )}
    >
      {user ? (
        isTabletOrMobile && (
          <Button
            className={`rounded-2 fs-12px hover-highlight ${styles.navCTA}`}
            href='/api/auth/logout'
            onClick={intercomShutdown}
          >
            Log Out
          </Button>
        )
      ) : (
        <>
          <Button
            id='signup-link'
            variant='primary'
            roundedClassName=''
            className={classNames(
              'px-4 px-xl-5 py-lg-2 fs-12px fs-md-14px hover-highlight mr-lg-05 mb-1 mb-lg-0',
              styles.navCTA,
              {
                [styles.authButtonDark]: isDark
              }
            )}
            href={pageRoutes.signUp}
            style={{ borderRadius: isTabletOrMobile ? '20px' : '16px' }}
          >
            {isTabletOrMobile ? 'Join Bezel' : 'Sign Up'}
          </Button>
          {isTabletOrMobile ? (
            <Button
              id='signup-link'
              variant='secondary'
              className={classNames('rounded-2 fs-12px hover-highlight', styles.navCTA, {
                [styles.darkNav]: isDark,
                [styles.brightOnHover]: !isDark
              })}
              href={pageRoutes.login}
            >
              Log {isTabletOrMobile ? 'in' : 'In'}
            </Button>
          ) : (
            <Nav.Link
              id='login-link'
              className={classNames(styles.navItem, 'me-2 me-xl-3', {
                [styles.darkNav]: isDark,
                [styles.brightOnHover]: !isDark
              })}
              href={pageRoutes.login}
            >
              Log In
            </Nav.Link>
          )}
        </>
      )}
      {user && !isTabletOrMobile && (
        <NavDropdown
          title={user.givenName}
          align='end'
          menuVariant='light'
          className={isDark ? styles.dropdownDark : undefined}
        >
          {dropdownItems.map(({ href, title }) => (
            <TopNavAuthDropdownItem key={title} onClick={() => push(href)} title={title} />
          ))}
          <NavDropdown.Divider />
          <TopNavAuthDropdownItem href='/api/auth/logout' onClick={intercomShutdown} title='Log Out' />
        </NavDropdown>
      )}
    </div>
  );
};
