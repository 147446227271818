import {
  createContext,
  createElement,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { noop } from 'utils/noop';

type ModalContextValue = {
  openModal: (component: React.ReactElement, savePrevModal?: boolean) => void;
  closeModal: () => void;
};
export const ModalContext = createContext<ModalContextValue>({ openModal: noop, closeModal: noop });

type ModalContextProviderProps = PropsWithChildren;

const ON_UNMOUNT_MODAL_INTERVAL = 300;

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<React.ReactElement | null>(null);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [previousModals, setPreviousModals] = useState<React.ReactElement[]>([]);
  const intervalRef = useRef<NodeJS.Timeout>();

  const modalOptions = useMemo(
    () => ({
      openModal: (modalComponent: React.ReactElement, savePrevModal = false) => {
        clearInterval(intervalRef.current);
        setModalIsOpened(true);
        setModal(currentModal => {
          if (currentModal && savePrevModal) {
            setPreviousModals(prevModals => [...prevModals, currentModal]);
          }

          return modalComponent;
        });
      },
      closeModal() {
        setModalIsOpened(false);
        intervalRef.current = setTimeout(() => {
          setModal(null);
          setPreviousModals([]);
        }, ON_UNMOUNT_MODAL_INTERVAL);
      }
    }),
    []
  );

  const onClickPrevious = useCallback(() => {
    const previousModal = previousModals[previousModals.length - 1];

    if (previousModal) {
      setModal(previousModal);
      setPreviousModals(previousModals.slice(0, previousModals.length - 1));
    }
  }, [previousModals]);

  useEffect(
    () => () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    },
    []
  );
  const previousBtnIsEnabled = previousModals.length > 0;

  return (
    <ModalContext.Provider value={modalOptions}>
      {modal &&
        createElement(modal.type, {
          isOpen: modalIsOpened,
          onClickPrevious: previousBtnIsEnabled ? onClickPrevious : undefined,
          ...modal.props
        })}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const modal = useContext(ModalContext);

  if (!modal) {
    throw new Error('ModalContext used outside of ModalContext.Provider!');
  }

  return modal;
};
