type SellersFooterLink = {
  title: string;
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
};

export const sellersFooterLinks: SellersFooterLink[] = [
  {
    title: 'Terms',
    href: 'https://www.getbezel.com/terms',
    target: '_blank'
  },
  {
    title: 'Privacy Policy',
    href: 'https://www.getbezel.com/privacy',
    target: '_blank'
  },
  {
    title: 'Buyer Protection Policy',
    href: 'https://www.getbezel.com/buyer-policy',
    target: '_blank'
  },
  {
    title: 'Authentication Policy',
    href: 'https://www.getbezel.com/authentication-policy',
    target: '_blank'
  }
];
