import { FC, useState } from 'react';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import { FaBuildingColumns } from '@react-icons/all-files/fa6/FaBuildingColumns';
import classNames from 'classnames';

import { PaymentModal } from 'components/checkout/payment/PaymentModal';
import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Spinner } from 'components/design-system/Spinner';
import { PaymentMethods } from 'consts/payment_methods';
import { useBezelUser } from 'context/bezelUser';
import styles from 'styles/Checkout.module.scss';
import { PaymentMethod, PaymentTransaction, StyledComponent } from 'types';

import { getCardBrandIcon } from '../getCardBrandIcon';

const PAYMENT_STYLE = { height: '74px', marginTop: '12px' };
const SELECTED_PAYMENT_ICON_STYLE = { backgroundColor: '#eef3ef', padding: '0.75rem' };

export const PaymentTestIds = {
  container: 'payment-container',
  selectPaymentMethodButton: 'payment-select-method-btn'
};

export type PaymentProps = {
  allowedPaymentTypes: string[];
  selectedPaymentMethod: PaymentMethod | null;
  setSelectedPaymentMethod: (val: PaymentMethod) => void;
  postSubmitNewCard: () => void;
  contentClassName?: string;
  onOpenPaymentModal?: () => void;
  includeTitle?: boolean;
  isDark?: boolean;
} & StyledComponent;

export const Payment: FC<PaymentProps> = ({
  allowedPaymentTypes,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  postSubmitNewCard,
  className,
  contentClassName,
  includeTitle = true,
  onOpenPaymentModal,
  isDark = false
}) => {
  const { user } = useBezelUser();
  const buyer = user?.buyerProfile;
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const openPaymentModal = () => {
    setPaymentModalOpen(true);
    onOpenPaymentModal?.();
  };

  const getSelectedPaymentStyles = () => {
    const standardClasses = `riforma-regular border rounded-2 ps-3 pe-4 py-2 mb-3 d-flex justify-content-between align-items-center ${contentClassName} ${
      isDark ? 'border-turquoise' : ''
    }`;

    return {
      style: PAYMENT_STYLE,
      className: standardClasses
    };
  };

  const paymentCreationCallback = (card: PaymentTransaction['paymentCard']) => {
    setSelectedPaymentMethod({
      type: PaymentMethods.Card,
      details: card
    });
    postSubmitNewCard();
  };

  if (!buyer) {
    return (
      <div className='w-100 text-center text-primary py-4 border rounded-2 mb-3' style={PAYMENT_STYLE}>
        <Spinner aria-hidden='true' />
      </div>
    );
  }

  const CardIcon = getCardBrandIcon(selectedPaymentMethod?.details?.brand);

  return (
    <div className={className} data-testid={PaymentTestIds.container}>
      {includeTitle && <span className={`riforma-regular fs-18px ${styles.sectionTitle}`}>Payment</span>}
      <div
        role='button'
        onClick={openPaymentModal}
        {...getSelectedPaymentStyles()}
        data-testid={PaymentTestIds.selectPaymentMethodButton}
      >
        <div>
          {!selectedPaymentMethod && (
            <>
              <div
                className={classNames('text-primary fs-14px', {
                  'text-turquoise': isDark
                })}
              >
                Pay with
              </div>
              <div className={classNames('text-secondary mt-1 fs-14px', { 'text-turquoise opacity-60': isDark })}>
                Add a payment method
              </div>
            </>
          )}
          {selectedPaymentMethod && selectedPaymentMethod.type === PaymentMethods.Card && (
            <div className='d-flex align-items-center fs-14px'>
              <div className='rounded-1' style={SELECTED_PAYMENT_ICON_STYLE}>
                <CardIcon className='text-success' size='1.5em' />
              </div>
              <div className='ms-3'>
                <div className='text-capitalize'>{selectedPaymentMethod.details?.brand}</div>
                <div className='riforma-regular opacity-60'>
                  {selectedPaymentMethod.details?.lastFour} -{' '}
                  {String(selectedPaymentMethod.details?.expMonth).padStart(2, '0')}/
                  {selectedPaymentMethod.details?.expYear}
                </div>
              </div>
            </div>
          )}
          {selectedPaymentMethod && selectedPaymentMethod.type === PaymentMethods.Wire && (
            <div className='d-flex align-items-center fs-14px'>
              <div className='rounded-1' style={SELECTED_PAYMENT_ICON_STYLE}>
                <FaBuildingColumns className='text-success' size='1.5em' />
              </div>
              <div className='ms-3'>
                <div className='text-primary'>Wire</div>
                <div className='text-success'>Saving 2.9%</div>
              </div>
            </div>
          )}
          {selectedPaymentMethod && selectedPaymentMethod.type === PaymentMethods.Affirm && (
            <div className='d-flex align-items-center fs-14px'>
              <div className='mx-3'>
                <CloudinaryImage src='affirm_black_logo-transparent_bg.png' alt='Affirm Logo' width={50} height={20} />
              </div>
              <div className='ms-3 my-3'>Affirm</div>
            </div>
          )}
          {selectedPaymentMethod && selectedPaymentMethod.type === PaymentMethods.Klarna && (
            <div className='d-flex align-items-center fs-14px'>
              <div className='mx-3'>
                <CloudinaryImage src='klarna_logo.png' alt='Klarna Logo' width={50} height={20} />
              </div>
              <div className='ms-3'>Klarna</div>
            </div>
          )}
        </div>
        <FaAngleRight className={isDark ? 'text-turquoise' : 'text-primary'} />
      </div>
      <PaymentModal
        buyer={buyer}
        allowedPaymentTypes={allowedPaymentTypes}
        isOpen={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        selectedPaymentMethod={selectedPaymentMethod}
        postSubmitNewCard={paymentCreationCallback}
        isDark={isDark}
      />
    </div>
  );
};
