import { FC } from 'react';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';

export type BezelLogoProps = {
  isDefault: boolean;
  logoHeight: number;
  onClick?: () => void;
};

export const BezelLogo: FC<BezelLogoProps> = ({ isDefault, logoHeight, onClick }) => (
  <Link href='/' className='d-flex' onClick={onClick}>
    <CloudinaryImage
      priority
      src={isDefault ? 'top-nav-logo.png' : 'logo-with-label-white.png'}
      width={100}
      height={logoHeight}
      alt='Bezel Logo'
      style={{ objectFit: 'contain' }}
    />
  </Link>
);
