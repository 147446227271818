import { FC } from 'react';
import { AiOutlineExclamationCircle } from '@react-icons/all-files/ai/AiOutlineExclamationCircle';

import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { PurchaseRequest } from 'types';

import { OfferExplanationSlideout } from './OfferExplanationSlideout';

type OfferAboveRequestInfoProps = {
  request: PurchaseRequest;
  refreshRequests?: () => void;
} & SlideoutCommonProps;

export const OfferAboveRequestInfo: FC<OfferAboveRequestInfoProps> = ({ request, refreshRequests }) => {
  const { closeModal, openModal } = useModal();

  const openOfferExplanationSlideout = () => {
    openModal(
      <OfferExplanationSlideout closeModal={closeModal} request={request} refreshRequests={refreshRequests} />,
      true
    );
  };

  return (
    <div
      onClick={openOfferExplanationSlideout}
      role='button'
      className='align-self-start d-flex align-items-center px-3 py-2 bg-primary06 border-radius-36 fs-12px'
    >
      <AiOutlineExclamationCircle size={20} />
      <div className='ms-2'>Why is this priced over your request?</div>
    </div>
  );
};
