import { KeyboardEvent, useState } from 'react';
import { HiOutlineSearch } from '@react-icons/all-files/hi/HiOutlineSearch';
import classNames from 'classnames';

import { Input } from 'components/design-system/Input';
import { COLORS } from 'consts';
import styles from 'styles/Seller.module.scss';
import { StyledComponent } from 'types';

type SearchComponentProps = {
  onSetSearchValue: (val: string) => void;
  placeholder?: string;
  inputClassName?: string;
  iconClassName?: string;
  inputWrapClassName?: string;
} & StyledComponent;

export const SearchComponent: React.FC<SearchComponentProps> = ({
  className,
  onSetSearchValue,
  placeholder = 'Search through your inventory',
  inputClassName = 'bg-transparent fs-12px fs-md-14px',
  iconClassName,
  inputWrapClassName
}) => {
  const [value, setValue] = useState('');

  const onUpdate = (val: string) => {
    setValue(val);
    onSetSearchValue(val);
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return (
    <div className={classNames('position-relative', className)}>
      <Input
        wrapClassName={inputWrapClassName}
        onKeyUp={onKeyUp}
        id='search-input'
        placeholder={placeholder}
        value={value}
        setValue={onUpdate}
        className={classNames('rounded-2 riforma-regular text-primary border-0', styles.search, inputClassName)}
      />
      <HiOutlineSearch
        className={classNames('absolute-centered-y start-0 ms-lg-3', iconClassName)}
        size={20}
        color={COLORS.primary}
      />
    </div>
  );
};
