import { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Search } from 'components/search/Search';
import { useGetSellerNavBarIsEnabled } from 'components/seller/navigationBar/useGetSellerNavBarIsEnabled';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import styles from 'styles/TopNav.module.scss';

import { ShopItemsDesktop } from './ShopItemsDesktop';

import { NavbarDropdownDesktop } from '../../NavbarDropdown';
import { TopNavAuthSection } from '../../TopNavAuthSection';
import { TopNavCheckout } from '../../TopNavCheckout';
import { TopNavStrip } from '../../TopNavStrip';
import { BezelLogo } from '../BezelLogo';
import { containerStyle } from '../constants';
import { useScroll } from '../useScroll';

export const TopNavDesktopTestIds = {
  container: 'top-nav-desktop-container'
};

const logoHeight = 40;

export const TopNavDesktop = () => {
  const router = useRouter();
  const scrolling = useScroll();

  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [navDropdownDesktopIsOpened, setNavDropdownDesktopIsOpened] = useState(false);
  const [navDropdownDesktopWasForcedClosed, setNavDropdownDesktopWasForcedClosed] = useState(false);
  const { isDefault } = useTheme();

  const sellerTopNavIsEnabled = useGetSellerNavBarIsEnabled();
  const forceClosedTopNavbar = () => setNavDropdownDesktopWasForcedClosed(true);

  const isVisible = !router.pathname.includes('/checkout');

  return (
    <>
      <div data-testid={TopNavDesktopTestIds.container} className='position-sticky top-0 w-100' style={containerStyle}>
        {isVisible && (
          <>
            <TopNavStrip />
            <Navbar
              collapseOnSelect
              onToggle={setNavbarExpanded}
              expand='lg'
              id='bezel-top-nav'
              style={{ backgroundColor: isDefault ? COLORS.primaryLight : COLORS.primaryDark }}
              className={classNames(
                'py-3 w-100',
                styles.navigation,
                scrolling && styles.fakeshadow,
                navbarExpanded && 'shadow'
              )}
              expanded={navbarExpanded}
            >
              <div className='container-fluid p-0 position-relative' style={{ minHeight: `${logoHeight}px` }}>
                <div className='d-flex flex-nowrap align-items-center justify-content-between flex-grow-1'>
                  {!sellerTopNavIsEnabled && <BezelLogo isDefault={isDefault} logoHeight={logoHeight} />}
                  <Search />
                </div>
                <Navbar.Collapse
                  id='responsive-navbar-nav'
                  className={classNames(styles['navbar-collapse'], 'riforma-regular')}
                >
                  <Nav className='my-1 ms-auto d-flex flex-lg-row justify-content-lg-start align-items-lg-center'>
                    <ShopItemsDesktop
                      navDropdownDesktopIsOpened={navDropdownDesktopIsOpened}
                      setNavDropdownDesktopIsOpened={setNavDropdownDesktopIsOpened}
                      setNavDropdownDesktopWasForcedClosed={setNavDropdownDesktopWasForcedClosed}
                    />
                    <TopNavAuthSection isTabletOrMobile={false} />
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
            <NavbarDropdownDesktop
              onClose={forceClosedTopNavbar}
              isOpen={navDropdownDesktopIsOpened}
              disabled={navDropdownDesktopWasForcedClosed}
            />
          </>
        )}
        {!isVisible && <TopNavCheckout scrolling={scrolling} logoWidth={logoHeight} />}
      </div>
    </>
  );
};
