import { CloudinaryImage } from 'components/common/CloudinaryImage';

import { StaticRequestItem } from './StaticRequestItem';

export const WatchBackgroundTestIds = {
  container: 'watch-background-container'
};

export const WatchBackground = () => (
  <div
    data-testid={WatchBackgroundTestIds.container}
    className='position-relative d-flex flex-column align-items-center'
  >
    <CloudinaryImage
      responsive
      alt='Request image'
      src='bezel-web/onboarding_hero_image.png'
      sizes='50vw'
      style={{ objectFit: 'contain', maxHeight: 400 }}
    />
    <StaticRequestItem />
  </div>
);
