import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

export const useIsInViewport = (customRef?: RefObject<Element>) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }

    return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));
  }, []);

  useEffect(() => {
    const elementRef = customRef ? customRef.current : videoRef?.current;

    if (elementRef) {
      observer?.observe(elementRef);
    }

    return () => {
      observer?.disconnect();
    };
  }, [customRef, observer]);

  return { isIntersecting, videoRef };
};
