import { getCookie } from 'cookies-next';

type Options = {
  contentType?: string;
  accessToken?: string | null;
  apiKey?: string;
  extraHeaders?: { name: string; value: string }[];
  returnRawResponse?: boolean;
};

export enum METHOD {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

export const clientApiRequest = async <Response>(
  url: string,
  { method, body }: { method: METHOD; body?: BodyInit },
  options: Options = { returnRawResponse: false }
): Promise<Response> => {
  const headers = new Headers();

  headers.append('X-Client', 'bezel-web');
  headers.append('Content-Type', options.contentType || 'application/json');
  if (options.accessToken) {
    headers.append('Authorization', `Bearer ${options.accessToken}`);
  }

  const anonymousId = getCookie('ajs_anonymous_id');

  if (anonymousId) {
    headers.append('X-Anonymous-Id', anonymousId);
  }

  if (options.apiKey) {
    headers.append('apiKey', options.apiKey);
  }

  if (options.extraHeaders) {
    options.extraHeaders.forEach(header => {
      headers.append(header.name, header.value);
    });
  }

  return fetch(url, {
    method,
    headers,
    body
  }).then(async res => {
    if (res.headers.get('content-type') === 'text/plain') {
      const data = await res.text();

      return data;
    }
    try {
      if (res.status === 204 || res.status === 404) {
        return Promise.resolve(null);
      }

      if (options.returnRawResponse) {
        return res;
      }

      const responseData = await res.json();

      return res.status >= 200 && res.status < 300 ? responseData : Promise.reject(responseData);
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  });
};

export const clientApiGet = <Response>(url: string, options?: Options): Promise<Response> =>
  clientApiRequest(url, { method: METHOD.Get }, options);

export const clientApiPost = <Response, Body = BodyInit>(
  url: string,
  body: Body,
  options?: Options
): Promise<Response> => {
  const bodyStringified = JSON.stringify(body);

  return clientApiRequest(url, { method: METHOD.Post, body: bodyStringified }, options);
};

export const clientApiPut = <Response, Body = BodyInit>(
  url: string,
  body: Body,
  options?: Options
): Promise<Response> => {
  const bodyStringified = JSON.stringify(body);

  return clientApiRequest(url, { method: METHOD.Put, body: bodyStringified }, options);
};

export const clientApiPatch = <Response, Body = BodyInit>(
  url: string,
  body: Body,
  options?: Options
): Promise<Response> => {
  const bodyStringified = JSON.stringify(body);

  return clientApiRequest(url, { method: METHOD.Patch, body: bodyStringified }, options);
};

export const clientApiDelete = <Response, Body = BodyInit>(
  url: string,
  body?: Body,
  options?: Options
): Promise<Response> => {
  const bodyStringified = JSON.stringify(body);

  return clientApiRequest(url, { method: METHOD.Delete, body: bodyStringified }, options);
};
