import { useEffect, useState } from 'react';

export const useScroll = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolling(window.pageYOffset > 0);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrolling;
};
