import { useMemo, useState } from 'react';

import { EQUINOX_UUID } from 'consts/storageKeys';
import { useBezelUser } from 'context/bezelUser';
import { clientApiPost } from 'lib/api_helper';
import { getFromStorage, removeFromStorage } from 'lib/storageHelpers';

export enum EquinoxEnrollmentState {
  NOT_ENROLLED = 'NOT_ENROLLED',
  ENROLLING = 'ENROLLING',
  ENROLLMENT_SUCCESSFUL = 'ENROLLMENT_SUCCESSFUL',
  ENROLLMENT_FAILED = 'ENROLLMENT_FAILED'
}

export const useEquinox = () => {
  const { user, isLoading: userIsLoading } = useBezelUser();

  const [enrollmentState, setEnrollmentState] = useState<EquinoxEnrollmentState>(EquinoxEnrollmentState.NOT_ENROLLED);
  const equinoxUuid = getFromStorage(EQUINOX_UUID);

  const showEquinoxBanner = useMemo(
    () =>
      (equinoxUuid && !user && !userIsLoading) || // user is not logged in and has a session
      (equinoxUuid && user && !user.enrollments?.find(enrollment => enrollment.program.key === 'EQUINOX')), // user is logged in and has a session but is not enrolled in Equinox
    [equinoxUuid, user, userIsLoading]
  );

  const enroll = async () => {
    if (equinoxUuid && user) {
      if (!user.enrollments?.find(enrollment => enrollment.program.key === 'EQUINOX')) {
        setEnrollmentState(EquinoxEnrollmentState.ENROLLING);
        try {
          await clientApiPost('/api/partnership/enrollments', {
            program: 'EQUINOX',
            data: { equinox_uuid: equinoxUuid }
          });
          setEnrollmentState(EquinoxEnrollmentState.ENROLLMENT_SUCCESSFUL);
        } catch {
          setEnrollmentState(EquinoxEnrollmentState.ENROLLMENT_FAILED);
        } finally {
          removeFromStorage(EQUINOX_UUID);
        }
      }
    }
  };

  return { showEquinoxBanner, isLoading: !user && userIsLoading, enroll, enrollmentState };
};
