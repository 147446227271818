import { useRouter } from 'next/router';
import classNames from 'classnames';

import styles from 'styles/TopNav.module.scss';

export type NavlinkShopProps = {
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseLeave: (e: React.MouseEvent) => void;
  onClick: (e: React.MouseEvent) => void;
  isActive?: boolean;
  className?: string;
};

export const NavlinkShopDesktop: React.FC<NavlinkShopProps> = ({
  onMouseMove,
  className,
  onMouseLeave,
  onClick,
  isActive
}) => {
  const router = useRouter();
  const isOnExplorePage = router.pathname.includes('/explore');

  return (
    <div
      className={classNames(className, styles.navItem, 'nav-link cursor-pointer', {
        [styles.active]: isActive,
        'text-dark': isOnExplorePage
      })}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      Shop
    </div>
  );
};
