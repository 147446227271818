import { Article } from './article';
import { Brand } from './brand';
import { Cluster } from './cluster';
import { Feature } from './feature';
import { Model } from './model';

export enum SECTIONS_TYPE {
  FeatureCluster = 'FEATURE_CLUSTER',
  ModelCluster = 'MODEL_CLUSTER',
  ListingCluster = 'LISTING_CLUSTER',
  BrandCluster = 'BRAND_CLUSTER',
  CollectionCluster = 'COLLECTION_CLUSTER',
  ArticleCluster = 'ARTICLE_CLUSTER',
  AuctionCluster = 'AUCTION_CLUSTER'
}

export type HomeFeedSectionItemType = Brand | Cluster | Model | Feature | Article;
export type ListObject<T> = {
  id: number;
  name: string;
  type: string;
  items: T[];
  created: string;
  updated: string;
};
export type HomeFeedSection<T> = {
  id: number;
  title: string;
  type: SECTIONS_TYPE;
  object: ListObject<T>;
  position: number;
};

export type HomeFeed<T> = {
  id: number;
  name: string;
  sections: HomeFeedSection<T>[];
  audience: string;
  created: string;
  updated: string;
};
