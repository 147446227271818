import { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from 'styles/TopNav.module.scss';

// 1 more than clip_to_bottom className
export const containerStyle: CSSProperties = { zIndex: 1021 };

export const commonTopnavStripClasses = classNames(
  'w-100 text-center py-3 text-light riforma-regular fs-14px top-nav-hover',
  styles.bannerStrip
);
