import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { SEARCH_API_QUERIES_MAP } from '../consts/queries';
import { QueryParams } from '../types/base';

export const buildListingUrl = (brandName: string, modelName: string, modelRef: string, listingId: number) => {
  const dashedBrandName = encodeURIComponent(preProcessURIComponent(brandName, true));
  const dashedModelName = encodeURIComponent(preProcessURIComponent(modelName));
  const dashedModelRef = encodeURIComponent('ref-' + preProcessURIComponent(modelRef));
  const dashedListingId = encodeURIComponent(preProcessURIComponent('id-' + listingId));

  return `/watches/${dashedBrandName}/${dashedModelName}/${dashedModelRef}/listing/${dashedListingId}`;
};

export const buildModelUrl = (brandName: string, modelName: string, modelRef: string, modelId: number) => {
  const dashedBrandName = encodeURIComponent(preProcessURIComponent(brandName, true));
  const dashedModelName = encodeURIComponent(preProcessURIComponent(modelName));
  const dashedModelRef = encodeURIComponent('ref-' + preProcessURIComponent(modelRef));
  const dashedModelId = encodeURIComponent(preProcessURIComponent('id-' + modelId));

  return `/watches/${dashedBrandName}/${dashedModelName}/${dashedModelRef}/${dashedModelId}`;
};

export const buildCollectionUrl = (collectionName: string, collectionId: number) => {
  const dashedCollectionName = encodeURIComponent(preProcessURIComponent(collectionName));
  const dashedCollectionId = encodeURIComponent(preProcessURIComponent('id-' + collectionId));

  return `/watches/collections/${dashedCollectionName}/${dashedCollectionId}`;
};

export const preProcessURIComponent = (component: string, dropPeriods = false) => {
  const updatedComponent = dropPeriods ? component.replace(/\./g, '') : component;

  return updatedComponent.replace(/ \/ /g, '-').replace(/ /g, '-').replace(/\//g, '-').trim().toLowerCase();
};

export const getEncodedString = (str: string, dropPeriod?: boolean) =>
  encodeURIComponent(preProcessURIComponent(str, dropPeriod));

export const convertSearchQueries = (queryParams: QueryParams = {}) =>
  Object.entries(queryParams).reduce<QueryParams>((acc, [queryKey, queryValue]) => {
    const key = SEARCH_API_QUERIES_MAP[queryKey] || queryKey;

    acc[key] = queryValue;

    return acc;
  }, {});

export const extendUrlByQueries = (url: string, queryParams: QueryParams = {}) => {
  const truthyQueryParams = omitBy(queryParams, isNil);

  return Object.entries(truthyQueryParams).reduce((acc, [queryKey, queryValue]) => {
    if (Array.isArray(queryValue)) {
      queryValue.forEach(val => {
        acc = buildUrlWithQueryParam(acc, queryKey, val);
      });

      return acc;
    }

    return buildUrlWithQueryParam(acc, queryKey, queryValue);
  }, url);
};

const buildUrlWithQueryParam = (
  currentUrl: string,
  queryKey: string,
  queryValue: string | number | boolean | undefined
) => {
  const chainingSign = currentUrl.indexOf('?') > -1 ? '&' : '?';

  currentUrl += `${chainingSign}${queryKey}=${queryValue}`;

  return currentUrl;
};
