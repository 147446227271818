import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import { Text } from 'components/design-system/Text';
import { StyledComponent } from 'types';

import styles from './Button.module.scss';

export type ButtonProps = {
  loading?: boolean;
  textClassname?: string;
  roundedClassName?: string;
} & BootstrapButtonProps &
  StyledComponent;

export const Button: React.FC<ButtonProps> = ({
  children,
  roundedClassName = 'rounded-2',
  className = '',
  variant = 'primary',
  textClassname,
  disabled,
  loading = false,
  ...rest
}) => (
  <BootstrapButton
    className={classNames(
      'd-flex justify-content-center align-items-center riforma-regular',
      styles.button,
      className,
      roundedClassName,
      styles[`variant-${variant}`],
      disabled && styles.disabled
    )}
    disabled={disabled}
    variant={variant}
    {...rest}
  >
    <Text fontWeight='light' className={classNames({ 'opacity-0': loading }, textClassname)}>
      {children}
    </Text>
    {loading && (
      <span className='absolute-centered'>
        <Spinner variant='secondary' animation='border' />
      </span>
    )}
  </BootstrapButton>
);
