import { Image } from './base';

export enum COLLECTION_TYPE {
  LISTING = 'LISTING',
  MODEL = 'MODEL'
}

export enum CollectionStyle {
  Simple = 'SIMPLE',
  FloatingImage = 'FLOATING_IMAGE'
}

export type Collection<T> = {
  id: number;
  name: string;
  key: string;
  formattedTitle: string;
  description: string;
  style: CollectionStyle;
  foregroundImages: Record<string, Image>;
  backgroundImages: Record<string, Image>;
  list: {
    id: number;
    name: string;
    type: COLLECTION_TYPE;
    items: T[];
  };
};
