import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import classNames from 'classnames';

import { normalizeSrc } from 'lib/helpers';

type DefaultImageProps = {
  overrideTransformations?: string[];
  isDark?: boolean;
};

type ResponsiveImage = {
  responsive: boolean;
  sizes: string;
  fill?: never;
};

type FilledImage = {
  fill: boolean;
  responsive?: never;
};

type Empty = {
  responsive?: never;
  fill?: never;
};

export type CloudinaryImageProps = (DefaultImageProps & ImageProps) & (ResponsiveImage | FilledImage | Empty);

export const cloudinaryLoader = ({ width, src, quality }: ImageLoaderProps, overrideTransformations?: string[]) => {
  const defaultTransformations = ['f_webp', 'c_limit', 'w_' + width, 'q_' + (quality || 'auto')];
  const paramsString = overrideTransformations ? overrideTransformations.join(',') : defaultTransformations.join(',');

  if (src.startsWith('https')) {
    return `${src}?tx=${paramsString}`;
  }

  return `${process.env.NEXT_PUBLIC_CLOUDINARY_STATIC_PREFIX}\\${normalizeSrc(src)}?tx=${paramsString}`;
};

const responsiveProps = {
  width: 1,
  height: 1
};

const responsiveStyle = { width: '100%', height: 'auto' };

export const CloudinaryImage: React.FC<CloudinaryImageProps> = ({
  overrideTransformations,
  responsive,
  fill,
  sizes,
  style,
  alt = 'Watch image',
  className,
  isDark,
  ...props
}) => (
  <Image
    alt={alt}
    loader={loaderProps => cloudinaryLoader(loaderProps, overrideTransformations)}
    fill={fill}
    sizes={sizes}
    // color the alt text
    className={classNames({ 'text-light': isDark }, className)}
    style={{
      ...(responsive ? responsiveStyle : {}),
      ...style
    }}
    {...(responsive ? responsiveProps : {})}
    {...props}
  />
);
