import { FC } from 'react';

import { Link } from 'components/common/Link';
import { useTheme } from 'context/theme';

import styles from './Footer.module.scss';

export const SectionTestIds = {
  container: 'section-container'
};

export type SectionProps = {
  title: string;
  links: { name: string; href: string }[];
  isTabletOrMobile: boolean;
};

export const Section: FC<SectionProps> = ({ title, links, isTabletOrMobile }) => {
  const { isDark } = useTheme();

  return (
    <div className='col-md-2 col-12 d-flex flex-column my-4' data-testid={SectionTestIds.container}>
      <div style={{ opacity: isDark ? 0.9 : 1 }} className='text-light fs-18px mb-2'>
        {title}
      </div>
      {links.map((link, idx) => (
        <Link
          key={`${title}-${link.name}-${idx}`}
          href={link.href}
          target='_blank'
          className={`${styles.link} fs-14px ${
            isTabletOrMobile ? 'py-3 border border-0 border-bottom border-secondary' : 'mt-3'
          }`}
        >
          {link.name}
        </Link>
      ))}
    </div>
  );
};
