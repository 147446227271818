import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { noop } from 'utils/noop';

export enum TopnavTitleKey {
  Default = 'default',
  OwnListing = 'OwnListing'
}

export const TopNavbarContext = createContext<{
  topNavbarTitleKey: TopnavTitleKey;
  setTopNavbarTitleKey: (key: TopnavTitleKey) => void;
}>({ topNavbarTitleKey: TopnavTitleKey.Default, setTopNavbarTitleKey: noop });

type TopNavbarProviderProps = PropsWithChildren;

export const TopNavbarProvider = ({ children }: TopNavbarProviderProps) => {
  const [topNavbarTitleKey, setTopNavbarTitleKey] = useState<TopnavTitleKey>(TopnavTitleKey.Default);

  const contextValue = useMemo(
    () => ({
      topNavbarTitleKey,
      setTopNavbarTitleKey
    }),
    [topNavbarTitleKey, setTopNavbarTitleKey]
  );

  return <TopNavbarContext.Provider value={contextValue}>{children}</TopNavbarContext.Provider>;
};

export const useGetTopNavbar = () => useContext(TopNavbarContext);
