import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { AnalyticsBrowser, ID } from '@segment/analytics-next';

import { noop } from 'utils/noop';

export type Analytics = {
  page: (url: string) => void;
  track: (key: string, payload?: object) => void;
  identify: (uuid?: string, payload?: object) => void;
  anonymousId: () => Promise<ID>;
  ready: boolean;
};

const defaultAnalytics = {
  page: noop,
  track: noop,
  identify: noop,
  anonymousId: () => Promise.resolve(undefined),
  ready: false
};
const noopAnalytics = {
  page: noop,
  track: noop,
  identify: noop,
  anonymousId: () => Promise.resolve(undefined),
  ready: true
};
const AnalyticsContext = createContext<Analytics>(defaultAnalytics);

const blockedUserAgents = ['AhrefsSiteAudit', 'Googlebot', 'DuckDuckBot', 'Bingbot'];

type AnalyticsProviderProps = {
  writeKey: string;
} & PropsWithChildren;

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children, writeKey }) => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | null>(null);

  const analyticsProxy = useMemo(() => {
    if (!analytics) {
      return defaultAnalytics;
    }

    const userAgent = window.navigator.userAgent.toLowerCase();

    for (const blockedUserAgent of blockedUserAgents) {
      if (userAgent.includes(blockedUserAgent.toLowerCase())) {
        return noopAnalytics;
      }
    }

    const page = (url: string) => analytics.page(url, { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const track = (key: string, payload?: object) =>
      analytics.track(key, { ...payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const identify = (uuid?: string, payload?: object) =>
      analytics.identify(uuid, { ...payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const anonymousId = async () => {
      if (analytics) {
        const user = await analytics.user();

        return user.anonymousId();
      }

      return null;
    };

    return { page, track, identify, anonymousId, ready: true };
  }, [analytics]);

  useEffect(() => {
    const analyticsBrowser = AnalyticsBrowser.load({ writeKey });

    analyticsBrowser.ready(() => {
      global.window.BezelAnalytics = analyticsBrowser;
      setAnalytics(analyticsBrowser);
    });
  }, [writeKey]);

  return <AnalyticsContext.Provider value={analyticsProxy}>{children}</AnalyticsContext.Provider>;
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);

  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }

  return result;
};
