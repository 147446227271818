import { Analytics } from 'context/analytics';
import { getFromStorage } from 'lib/storageHelpers';

export const fireTrackEvent = (analytics: Analytics, key: string, payload: Record<string, unknown>) => {
  const stringPayload = stringifyPayload(payload);

  analytics.track(key, stringPayload);
};

export const stringifyPayload = (payload: Record<string, unknown> | undefined) => {
  if (!payload) {
    return {};
  }

  const newPayload: Record<string, string> = {};

  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      newPayload[key] = payload[key] ? String(payload[key]) : '';
    }
  }

  return newPayload;
};

export const getAttributionToken = () => getFromStorage('attribution');
