import { FC } from 'react';
import { HiArrowLeft } from '@react-icons/all-files/hi2/HiArrowLeft';
import classNames from 'classnames';

import styles from './Slideout.module.scss';

type SlideoutBackButtonProps = {
  isDark?: boolean;
  onClickPrevious?: () => void;
  className?: string;
  testID?: string;
};

export const SlideoutBackButton: FC<SlideoutBackButtonProps> = ({ isDark, onClickPrevious, className, testID }) => (
  <div
    data-testid={testID}
    role='button'
    onClick={onClickPrevious}
    className={classNames(styles.iconContainer, styles.backIcon, className, {
      'text-light bg-dark-grey': isDark,
      'text-primary bg-light': !isDark
    })}
  >
    <HiArrowLeft size={28} />
  </div>
);
