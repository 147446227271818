import { FC } from 'react';

import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { PurchaseRequest } from 'types';

import { CloseRequestSlideout } from '../CloseRequestSlideout';

type OfferExplanationSlideoutProps = {
  request: PurchaseRequest;
  refreshRequests?: () => void;
} & SlideoutCommonProps;

export const OfferExplanationSlideout: FC<OfferExplanationSlideoutProps> = ({ request, refreshRequests, ...props }) => {
  const { closeModal, openModal } = useModal();

  const openCloseRequestSlideout = () => {
    openModal(
      <CloseRequestSlideout refreshRequests={refreshRequests} request={request} closeModal={closeModal} />,
      true
    );
  };
  const submitButton = {
    title: 'Cancel your request',
    onClick: openCloseRequestSlideout
  };

  return (
    <Slideout
      {...props}
      bodyClassName='d-flex flex-column'
      submit={submitButton}
      title='Why is this price over your requested price?'
    >
      <div className='riforma-regular text-center flex-1 d-flex flex-column justify-content-around'>
        We allow sellers to submit watches that are priced between your request and the lowest available listing
      </div>
    </Slideout>
  );
};
