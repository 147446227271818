import { useEffect } from 'react';
import { setCookie } from 'cookies-next';

import { useBezelUser } from 'context/bezelUser';
import { useViewport } from 'context/viewport';

import { TopNavDesktop } from './TopNavDesktop';
import { TopNavMobile } from './TopNavMobile';

export const TopNav = () => {
  const { isTabletOrMobile, isLoaded } = useViewport();
  const { user } = useBezelUser();

  useEffect(() => {
    if (user) {
      setCookie('user_id', user.id);
    }
  }, [user]);

  if (!isLoaded) {
    return null;
  }

  if (isTabletOrMobile) {
    return <TopNavMobile />;
  }

  return <TopNavDesktop />;
};
