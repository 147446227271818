import { EquinoxModal } from 'components/equinox/EquinoxModal';
import { useModal } from 'context/modal/ModalContext';

import { commonTopnavStripClasses } from '../TopNav/constants';

export const EquinoxRibbon = () => {
  const { openModal, closeModal } = useModal();

  const gotoEquinoxModal = () => openModal(<EquinoxModal closeModal={closeModal} />);

  return (
    <div className={commonTopnavStripClasses} onClick={gotoEquinoxModal} role='button'>
      <span className='d-none d-sm-inline'>Equinox Members, create an account to unlock exclusive benefits!</span>
      <span className='d-inline d-sm-none'>Exclusive Equinox benefits!</span>
      <span className='text-light text-uppercase ms-4 opacity-60 d-none d-sm-inline'>Check it out</span>
    </div>
  );
};
