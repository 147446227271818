import { useEffect } from 'react';

import { intercomHideLauncher, intercomShowLauncher, isIntercomDefined } from 'lib/intercom';

// hide intercom from current component, but make it visible after component unmounts
export const useHideIntercom = () => {
  useEffect(() => {
    const isDefined = isIntercomDefined();

    if (!isDefined) {
      return;
    }
    intercomHideLauncher();

    return () => {
      intercomShowLauncher();
    };
  }, []);
};
